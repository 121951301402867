import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import { quizzesSlice } from "./reducers/quizzes/quizzesSlice";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  // authentication: authSlice.reducer,
  quizzes: quizzesSlice.reducer
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
