import React, { useEffect, useState } from "react";
import { useStyles } from "./styles";
import {
  deleteSooses,
  getAllSooses,
  getSoosById,
  updateSoosApi,
} from "../../redux/reducers/sooses/soosCrud";
import moment from "moment";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import trash from "../../../src/assets/images/trash.png";
import edit from "../../../src/assets/images/editing.png";
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import toast, { Toaster } from "react-hot-toast";
import { NavLink } from "react-router-dom";
import { useHistory } from "react-router";
import { string } from "prop-types";
import { Input, DatePickerField } from "../../_metronic/_partials/controls";
import { Formik, Form, Field, FieldArray } from "formik";
import * as Yup from "yup";
import { Modal, Spinner } from "react-bootstrap";
import searchIcon from "../../assets/images/search-icon.svg";
import { deleteBlogs, getAllBlogs } from "./blogsCrud";
// const questionInitialValues = {
//   answer: ""
// };
interface Item {
  id: number;
  title: string;
}

const BlogsPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const [blogsTableData, setBlogsTableData] = useState([]);
  const [openTrashDialogBox, setOpenTrashDialogBox] = useState(false);
  const [title, setTitle] = useState("");
  const [openFilterDialogBox, setOpenFilterDialogBox] = useState(false);
  const [sooDeleteId, setSooDeleteId] = useState();
  const [page, setPage] = useState(1);
  const [dataLimit, setDataLimit] = useState(10);
  const [searchByDate, setSearchByDate] = useState<string>();
  const [filterSoosDataInfoFoam, setFilterSoosDataInfoFoam] = useState<any>();
  const [createSoosDataInfoFoam, setCreateSoosDataInfoFoam] = useState<any>();
  const [startOfDay, setStartOfDay] = useState<any>("");
  const [endOfDay, setEndOfDay] = useState<any>("");
  const [startDate, setStartDate] = useState<any>("");
  const [endDate, setEndDate] = useState<any>("");
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    // getSoosesData(page);
    setTimeout(() => {
      setPage(1);
      getBlogData(title, page);
    }, 1500);
  }, []);

  async function getBlogData(title: any, page: any) {
    try {
      setLoading(true);
      let res = await getAllBlogs(title, page);
      if (res) {
        setPage(page);
        console.log(res, "soosres");
        setBlogsTableData(res?.data?.results);
        setLoading(false);
      }
    } catch (e) {}
  }
  async function deleteSelectedSosses(item: any) {
    try {
      await deleteBlogs(item)
        .then((res: any) => {
          getBlogData(title, page);
          toast.success("Deleted successfully");
        })
        .catch((e: any) => {
          toast.error(e?.response?.data?.message);
        });
    } catch (e) {}
  }
  function getDatelocalIntoUtc(date: any, format: any) {
    if (date) {
      return moment(date)
        .utc()
        .format();
    }
  }
  function getDateUtcIntoLocal(date: any, formate: any) {
    if (date) {
      return moment
        .utc(date)
        .local()
        .format(formate);
    }
  }
  const handleClickToOpenTrashDialogBox = (item: any) => {
    setOpenTrashDialogBox(true);
    setSooDeleteId(item);
  };
  const handleToClose = () => {
    setOpenTrashDialogBox(false);
  };
  const handleToOpenFilterDialog = () => {
    setOpenFilterDialogBox(true);
  };
  const handleToCloseFilterDialog = () => {
    setOpenFilterDialogBox(false);
  };

  const setPagination = () => {
    return (
      <div className="d-flex justify-content-end">
        {page > 1 && (
          <div
            onClick={() => {
              setPage(page - 1);
              getBlogData(title, page - 1);
            }}
          >
            <div className="cursor-pointer text-primary">Prev</div>
          </div>
        )}

        <div className="text-info pl-2 pr-2">{page}</div>
        {blogsTableData.length == dataLimit && (
          <div
            className="cursor-pointer text-primary"
            onClick={() => {
              setPage(page + 1);
              getBlogData(title, page + 1);
            }}
          >
            Next
          </div>
        )}
      </div>
    );
  };
  const CustomerEditSchema = Yup.object().shape({
    title: Yup.mixed()
      // .nullable()
      .required("title is required"),
    endDate: Yup.mixed()
      // .nullable()
      .required("End Date is required"),
  });

  const desiredWidth = 20;
  const desiredHeight = 20;
  return (
    <>
      <Toaster />

      <div className="container-fluid">
        <div className="d-flex col-12 justify-content-end align-items-center">
          <div
            style={{
              border: "2px solid #3699FF",
              borderRadius: "8px",
              overflow: "hidden",
            }}
            className="col-xl-8 d-flex mb-2 align-items-center filter-search-icon check"
          >
            <div className="loader"></div>
            <>
              <input
                style={{
                  border: "none",
                  backgroundColor: "transparent",
                  outline: "none",
                }}
                value={title}
                className="col-xl-11 col-md-10 col-7 d-flex align-items-center pt-2 pb-2"
                placeholder="Search Title"
                onChange={(e: any) => {
                  // console.log(e.target.value)
                  setTitle(e.target.value);
                }}
              />
              <div
                className="col-xl-1 col-md-2 col-5 d-flex align-items-center justify-content-center "
                style={{ backgroundColor: "#E1F0FF", borderRadius: "6px" }}
              >
                {" "}
                <button
                  className="btn btn-light-primary font-weight-bold"
                  onClick={() => {
                    getBlogData(title, page);
                  }}
                >
                  Find
                </button>
              </div>
            </>
          </div>
          <div className="col-xl-4 d-flex justify-content-end">
            {/* <div className="d-flex pr-2">
            <button
              className="btn btn-light-primary font-weight-bold mb-2"
              type="button"
              onClick={()=>setOpenFilterDialogBox(!openFilterDialogBox)}
            >
              Filter
            </button>
          </div> */}
            <div className="d-flex pr-2">
              <button
                className="btn btn-light-primary font-weight-bold mb-2"
                type="button"
                onClick={() => {
                  setTitle("");
                  getBlogData("", 1);
                }}
              >
                Reset
              </button>
            </div>

            <button
              className="btn btn-light-primary font-weight-bold mb-2"
              onClick={() => {
                history.push(`/create/blogs`);
              }}
            >
              Create Blog
            </button>
          </div>
        </div>
        <Dialog open={false} onClose={handleToClose}>
          <DialogTitle>{"Filter"}</DialogTitle>
          <DialogContent>
            <section>
              <Formik
                enableReinitialize={true}
                initialValues={{ startDate: startDate, endDate: endDate }}
                validationSchema={CustomerEditSchema}
                validate={(values) => {
                  {
                    setFilterSoosDataInfoFoam(values);
                  }
                }}
                onSubmit={(values) => {
                  {
                  }
                  console.log(
                    "submit",
                    filterSoosDataInfoFoam.startDate,
                    filterSoosDataInfoFoam.endDate
                  );
                }}
              >
                {({ handleSubmit }) => (
                  <>
                    <Modal.Body className="overlay overlay-block cursor-default">
                      <Form className="form form-label-right" placeholder={""}>
                        <div className="form-group row">
                          <div className="col-lg-6 mb-4 date-picker-wrapper">
                            <DatePickerField
                              name="startDate"
                              label="Start Date*"
                              showTimeSelect={false}
                              dateFormat="MM/dd/yyyy"
                            />
                          </div>
                          <div className="col-lg-6 mb-4 date-picker-wrapper">
                            <DatePickerField
                              name="endDate"
                              label="End Date*"
                              showTimeSelect={false}
                              dateFormat="MM/dd/yyyy"
                            />
                          </div>
                        </div>
                      </Form>
                    </Modal.Body>
                    <Modal.Footer>
                      <button
                        type="button"
                        onClick={handleToCloseFilterDialog}
                        className="btn btn-light-primary font-weight-bold"
                      >
                        Cancel
                      </button>
                      <> </>
                      <button
                        type="submit"
                        onClick={() => {
                          handleSubmit();
                        }}
                        className="btn btn-light-primary font-weight-bold"
                      >
                        Find
                      </button>
                    </Modal.Footer>
                  </>
                )}
              </Formik>
            </section>
          </DialogContent>
        </Dialog>
        <div className="">
          <form>
            {loading && (
              <div className="centered">
                <CircularProgress className="splash-screen-spinner" />
              </div>
            )}
            <table className="table table-striped table-hover text-center fs-5 table-boardered">
              <thead className="table-primary">
                <tr>
                  <th style={{ width: "120px" }} id="tr">
                    Title
                  </th>
                  {/* <th style={{ width: "240px" }} id="tr">
                    Detail
                  </th> */}
                  <th style={{ width: "80px" }} id="tr">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {blogsTableData?.map(
                  (
                    item: {
                      id: any;
                      title: any;
                      shortDetail: any;
                    },
                    index
                  ) => {
                    return (
                      <>
                        <tr key={index}>
                          <td>{item.title}</td>
                          {/* <td className="d-flex text-justify">
                            {item.shortDetail}
                          </td> */}
                          <td>
                            <Dialog
                              open={openTrashDialogBox}
                              onClose={handleToClose}
                            >
                              <DialogTitle>
                                {"Are you sure you want to delete?"}
                              </DialogTitle>
                              <DialogContent>
                                <div className="d-flex justify-content-around">
                                  <button
                                    className="btn btn-light-primary font-weight-bold mb-2"
                                    onClick={handleToClose}
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    className="btn btn-light-primary font-weight-bold mb-2"
                                    onClick={() => {
                                      deleteSelectedSosses(sooDeleteId);
                                      handleToClose();
                                    }}
                                  >
                                    Delete
                                  </button>
                                </div>
                              </DialogContent>
                            </Dialog>
                            <img
                              className="cursor-pointer"
                              width={18}
                              height={18}
                              src={trash}
                              alt=""
                              onClick={() => {
                                handleClickToOpenTrashDialogBox(item.id);
                              }}
                            />
                            <img
                              className="cursor-pointer"
                              width={18}
                              height={16}
                              src={edit}
                              alt=""
                              onClick={() => {
                                history.push(`/editBlogs/${item.id}`);
                              }}
                            />
                          </td>
                        </tr>
                      </>
                    );
                  }
                )}
              </tbody>
            </table>
            {setPagination()}
          </form>
        </div>
      </div>
    </>
  );
};

export default BlogsPage;
