import { createUseStyles } from "react-jss";
import { Colors, Global } from "../../uiCore/theme";

export const useStyles = createUseStyles({
  defaultQuestions: {
    transition: Global.transition,
    paddingLeft: "0 !important",
    listStyle: "none",
    "& li": {
      fontSize: 20,
      position: "relative",
      margin: [30, 0],
      fontFamily: Global.fontPrimary,
      "& h2": {
        fontSize: 20,
        margin: 0,
        letterSpacing: 2,
        padding: 0,
        display: "inline-block",
        "@media screen and (max-width: 680px)": {
          fontSize: 30,
        },
        "@media screen and (max-width: 400px)": {
          fontSize: 20,
        },
      },
      "& hr": {
        content: '""',
        width: 50,
        height: 3,
        background: Colors.themePrimayColor,
        opacity: 1,
        marginTop: 30,
        marginBottom: 30,
      },
      "& textarea": {
        borderColor: Colors.themePrimayColor,
        borderRadius: 0,
        marginTop: 15,
        marginBottom: 20,
        padding: [15, 20],
        fontSize: 22,
        "@media screen and (max-width: 680px)": {
          fontSize: 16,
        },
      },
      "&:focus-within": {
        "& h2": {
          color: Colors.themePrimayColor,
        },
        "& textarea": {
          boxShadow: `0 0 5px ${Colors.themePrimayColor}`,
        },
        "&::before": {
          color: Colors.themePrimayColor,
        },
      },
    },
  },
  quizQuestions: {
    transition: Global.transition,
    paddingLeft: 0,
    listStyle: "none",
    fontSize: 20,
    counterReset: "list",
    "& li": {
      // margin: [10, 0],
      position: "relative",

      "& h2, input": {
        fontSize: 18,
        margin: 0,
        letterSpacing: 2,
        padding: 0,
        display: "flex",
        alignItems: "center",
        width: "100%",
        "& input": {
          padding: ".5rem",
        },
        "&::before": {
          minWidth: "250px",
          content: "'Question ('counter(list, lower-alpha)'): '",
          counterIncrement: "list",
        },
      },
      "& h3, input": {
        fontSize: 18,
        margin: 0,
        letterSpacing: 2,
        padding: 0,
        display: "flex",
        alignItems: "center",
        width: "100%",
        "& input": {
          padding: ".5rem",
        },
        "&::before": {
          minWidth: "250px",
          content: "'Answer('counter(list, lower-alpha)'): '",
        },
      },
      "& hr": {
        content: '""',
        width: 60,
        height: 3,
        background: Colors.themePrimayColor,
        opacity: 1,
        margin: 0,
        display: "inline-block",
        position: "absolute",
        bottom: 20,
        left: 0,
      },

      "& textarea": {
        borderColor: Colors.themePrimayColor,
        borderRadius: 0,
        marginTop: 15,
        // marginBottom: 20,
        padding: [15, 20],
        fontSize: 18,
      },
      "&:focus-within": {
        color: Colors.themePrimayColor,
        outline: "none !important",
        border: "none !important",
        boxShadow: "none",

        "& textarea, input": {
          boxShadow: `0 0 5px ${Colors.themePrimayColor}`,
        },
        "&::before": {
          color: Colors.themePrimayColor,
        },
      },
    },
  },

  btnCheckout: {
    borderRadius: "100%",
    width: "70px !important",
    maxWidth: 70,
    maxHeight: 70,
    height: 70,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    right: "1rem",
    bottom: "0",
    transform: "translateY(0)",
    padding: "0 !important",
    margin: "0 !important",
    flexDirection: "column",
    transaction: Global.transition,
    "&.active": {
      transform: "translateY(-180px)",
    },
    "& .btn-icon": {
      margin: "0 !important",
      float: "none !important",
    },
    "& img": {
      width: 35,
      maxWidth: 35,
    },
    "& .counter": {
      background: "red",
      borderRadius: "100%",
      width: 20,
      height: 20,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "absolute",
      right: "-.25rem",
      top: "-.25rem",
    },
  },
});
