import axios from "axios";
export const GET_SOOSES_URL = "blogs";

export function getAllBlogs(title: any, page: any) {
  return axios.get(
    `${GET_SOOSES_URL}?title=${title}&page=${page}&limit=10&sortBy=&isActive=`
  );
}
export function createBlogsApi(
  title: any,
  shortDetail: any,
  detail: any,
  picture: any
) {
  return axios.post(
    `${GET_SOOSES_URL}/`,
    {
      title: title,
      shortDetail: shortDetail,
      detail: detail,
      picture: picture,
    },
    {
      headers: {
        Authorization: `Bearer ${"access-token-" + Math.random()}`,
      },
    }
  );
}
export function uploadPictureApi(file: any) {
  const formData = new FormData();
  formData.append("file", file);

  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${"access-token-" + Math.random()}`,
    },
  };

  return axios.post(`${GET_SOOSES_URL}/upload/picture`, formData, config);
}
export function deleteBlogs(item: any) {
  return axios.delete(`${GET_SOOSES_URL}/${item}`);
}
export function deletePictureApi(path: any) {
  return axios.delete(`${GET_SOOSES_URL}/delete/picture?path=${path}`);
}
export function getBlogsById(item: any) {
  return axios.get(`${GET_SOOSES_URL}/${item}`, {
    headers: {
      Authorization: `Bearer ${"access-token-" + Math.random()}`,
    },
  });
}
export function updateBlogApi(
  item: any,
  title: any,
  shortDetail: any,
  detail: any,
  picture: any,
  isActive: any
) {
  return axios.put(
    `${GET_SOOSES_URL}/${item}`,
    {
      title: title,
      shortDetail: shortDetail,
      detail: detail,
      picture: picture,
      isActive: isActive,
    },
    {
      headers: {
        Authorization: `Bearer ${"access-token-" + Math.random()}`,
      },
    }
  );
}
