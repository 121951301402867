import axios from "axios";

export const GET_SOOSES_URL = "soos";

export function getAllSooses(page: any, startDateUTC: any, endDateUTC: any) {
  return axios.get(
    `${GET_SOOSES_URL}?page=${page}&limit=8&sortBy=startDateTime:asc&startDate=${startDateUTC}&endDate=${endDateUTC}&isFromAdmin=true`
  );
}
export function deleteSooses(item: any) {
  return axios.delete(`${GET_SOOSES_URL}/${item}`);
}
export function getSoosById(item: any) {
  return axios.get(`${GET_SOOSES_URL}/${item}`, {
    headers: {
      Authorization: `Bearer ${"access-token-" + Math.random()}`,
    },
  });
}
export function createSoosApi(
  name: any,
  price: any,
  totalCount: any,
  startDateTime: any,
  endDateTime: any,
  timeOfDay: any,
  registrationEndTime: any
) {
  return axios.post(
    `${GET_SOOSES_URL}/`,
    {
      name: name,
      price: price,
      totalCount: totalCount,
      startDateTime: startDateTime,
      endDateTime: endDateTime,
      timeOfDay: timeOfDay,
      registrationEndTime: registrationEndTime,
    },
    {
      headers: {
        Authorization: `Bearer ${"access-token-" + Math.random()}`,
      },
    }
  );
}
export function updateSoosApi(
  item: any,
  name: any,
  price: any,
  totalCount: any,
  startDateTime: any,
  endDateTime: any,
  timeOfDay: any,
  registrationEndTime: any
) {
  return axios.put(
    `${GET_SOOSES_URL}/${item}`,
    {
      name: name,
      price: price,
      totalCount: totalCount,
      startDateTime: startDateTime,
      endDateTime: endDateTime,
      timeOfDay: timeOfDay,
      registrationEndTime: registrationEndTime,
    },
    {
      headers: {
        Authorization: `Bearer ${"access-token-" + Math.random()}`,
      },
    }
  );
}
