import React from "react";
import { Card } from "react-bootstrap";
import { useStyles } from "../cards/styles";
import IconCheckCircle from "../../assets/images/icon-check-circle.svg";

interface QuizItemProps {
  title?: string;
  price?: string;
  action?: string;
  className?: string;
  purchased?: boolean;
  attempted?: boolean;
  userQuizId?: string;
  onClick?: any;
}

export const QuizItem = ({
  title = "",
  price = "",
  action = "",
  className = "",
  purchased = false,
  attempted = false,
  userQuizId,
  onClick = () => {},
}: QuizItemProps) => {
  const classes = useStyles();
  return (
    <Card className={`${classes.cardQuiz} ${className}`}>
      <h2>{title}</h2>
      <h1>
        <span>$ {price}</span>{" "}
        <button className="card-action" onClick={onClick}>
          {action}
        </button>
      </h1>
      {purchased && (
        <div className={"badge-purchased"}>
          <span className="icon-wrap">
            <img src={IconCheckCircle} alt="Quiz Purches" />
          </span>
          <p>Purchased</p>
        </div>
      )}
    </Card>
  );
};
