import { createSlice } from "@reduxjs/toolkit";
// import { toast } from "react-hot-toast";

const initialQuizzesState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  partsEntities: null,
  quizEntities: null,
  singleQuiz: null,
  singleQuizQuestions: null,
  userQuizzesEntities: null,
  userQuizEntities: null,
  cartEntities: null,
  lastError: null,
  navigate: false,
  error: null,
  loading: false,
  toast: false
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const quizzesSlice = createSlice({
  name: "quizzes",
  initialState: initialQuizzesState,
  reducers: {
    catchError: (state, action) => {
      state.error = action.payload.error;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      state.navigate = false;
      state.loading = false;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // findQuizzes
    quizzesFetched: (state, action) => {
      state.error = null;
      state.loading = true;
      state.listLoading = false;
      state.entities = action.payload.entities;
    },

    // getQuizById
    quizFetched: (state, action) => {
      state.error = null;
      state.loading = true;
      state.actionsLoading = false;
      state.singleQuiz = action.payload.singleQuiz;
      state.singleQuizQuestions = action.payload.singleQuizQuestions;
    },

    // findUserQuizzes
    userQuizzesFetched: (state, action) => {
      state.error = null;
      state.loading = true;
      state.listLoading = false;
      state.userQuizzesEntities = action.payload.userQuizzesEntities;
    },
    // findUserQuizzes
    userQuizFetched: (state, action) => {
      state.error = null;
      state.loading = true;
      state.listLoading = false;
      state.userQuizEntities = action.payload.userQuizEntities;
    },
    // cartItems
    cartItemEntities: (state, action) => {
      state.actionsLoading = false;
      state.cartEntities = action.payload.cartItemEntities;
      state.error = null;
      state.loading = true;
    }
  }
});
