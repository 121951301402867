import React from "react";
import { useStyles } from "./styles";

interface PaginationProps {
  totalPages?: any;
  currPage?: any;
  activePage?: any;
  pageIndex?: any;
  paginate?: any;
  onClick?: any;
  onChange?: any;
}

export const Pagination = ({
  totalPages,
  currPage,
  activePage,
  onClick = () => {},
  onChange = () => {}
}: PaginationProps) => {
  const classes = useStyles();

  return (
    <nav>
      <ul className={classes.pagination}>
        {Array.from(Array(totalPages).keys()).map(index => (
          <li key={index}>
            <button
              className={index === currPage - 1 ? "active-page" : ""}
              type="button"
              onClick={() => onClick(index + 1)}
              onChange={() => onChange(index)}
              disabled={false}
            >
              {index + 1}
            </button>
          </li>
        ))}
      </ul>
    </nav>
  );
};
