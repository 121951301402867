import React, { useEffect, useRef, useState } from "react";
import { useStyles } from "./styles";
import toast, { LoaderIcon, Toaster } from "react-hot-toast";
import { NavLink } from "react-router-dom";
import { useHistory } from "react-router";
import { Col, Container, Row } from "react-bootstrap";
// import Calendar from "react-input-calendar";
import { Formik, Form, Field, FieldArray } from "formik";
import { Modal } from "react-bootstrap";
import {
  Input,
  Select,
  DatePickerField,
} from "../../_metronic/_partials/controls/";
import { createSoosApi } from "../../redux/reducers/sooses/soosCrud";
import * as Yup from "yup";
import moment from "moment";
import { CircularProgress } from "@material-ui/core";
import { Editor } from "@tinymce/tinymce-react";
import { createBlogsApi, uploadPictureApi } from "./blogsCrud";

// const questionInitialValues = {
//   answer: ""
// };
const DetailBlogs = () => {
  const classes = useStyles();
  const history = useHistory();
  const [createSoosDataInfoFoam, setCreateSoosDataInfoFoam] = useState<any>();
  const [createSoosDataProcessing, setCreateSoosDataProcessing] = useState(
    false
  );
  const handleEditorChange = (content: any, editor: any) => {
    console.log("Content was updated:", content);
    const htmlContent = content;
    setDetails(htmlContent);
  };
  const [selectedImage, setSelectedImage] = useState(null);
  const [title, setTitle] = useState("");
  const [shortDetail, setShortDetail] = useState("");
  const [details, setDetails] = useState("");
  const [picture, setPicture] = useState("");
  const [pictureUrl, setPictureUrl] = useState("");
  const editorRef: any = useRef();
  async function addCreateBlogs(
    title: any,
    shortDetail: any,
    detail: any,
    pictureUrl: any
  ) {
    setCreateSoosDataProcessing(true);
    await createBlogsApi(title, shortDetail, detail, pictureUrl)
      .then((res) => {
        console.log("res", res);
        setCreateSoosDataProcessing(false);
        toast.success("Successfully Created");
        history.push(`/blogs`);
      })
      .catch((e) => {
        console.log(e);
        setCreateSoosDataProcessing(false);
        toast.error(e?.response?.data?.message);
      });
  }
  async function uploadPicture(file: any) {
    console.log(file, "checkkk");
    setCreateSoosDataProcessing(true);
    await uploadPictureApi(file)
      .then((res) => {
        console.log("res", res);
        addCreateBlogs(title, shortDetail, details, res?.data?.url);
      })
      .catch((e) => {
        console.log(e);
        setCreateSoosDataProcessing(false);
        toast.error(e?.response?.data?.message);
      });
  }
  const handleImageChange = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      // Specify the maximum allowed file size in bytes (e.g., 1 MB)
      const maxSize = 5000 * 5000;

      if (file.size > maxSize) {
        alert(
          "File size exceeds the 5MB limit. Please choose a smaller image."
        );
        // Clear the input field if the file size is too large
        e.target.value = null;
        return;
      } else {
        setPicture(file);
        const reader = new FileReader();

        reader.onload = (e: any) => {
          setSelectedImage(e.target.result);
        };

        reader.readAsDataURL(file);
      }
    }
  };
  const handleRemoveImage = () => {
    setSelectedImage(null);
  };
  const checkValidation = () => {
    if (title) {
      uploadPicture(picture);
    } else {
      toast.error("title is required");
    }
  };
  useEffect(() => {}, []);
  const desiredWidth = 600;
  const desiredHeight = 300;

  return (
    <>
      <Toaster />
      <div className="container-fluid">
        <div className="d-flex justify-content-start">
          <button
            className="btn btn-light-primary font-weight-bold mb-2"
            onClick={() => {
              history.push(`/blogs`);
            }}
          >
            Back
          </button>
        </div>
        <section className="">
          <div className="col-md-12 col-12 d-flex justify-content-center">
            <div className="col-md-8 col-12">
              <div className="form-group form-label-group mb-4">
                {selectedImage && (
                  <img
                    className="col-12"
                    src={selectedImage}
                    // height="200px"
                    alt=""
                    style={{
                      width: "100%", // Make sure the image takes the full width of its container
                      height: "auto", // Maintain the aspect ratio
                      borderRadius: "5%",
                      objectFit: "contain",
                    }}
                  />
                )}
                <div className="col-12 d-flex justify-content-center">
                  <div className="mt-4">
                    {!selectedImage ? (
                      <input
                        className="btn btn-light-primary font-weight-bold"
                        type="file"
                        onChange={handleImageChange}
                        accept="image/*"
                      />
                    ) : (
                      <>
                        <div className="d-flex">
                          {/* <button
                            type="button"
                            onClick={() => {
                              uploadPicture(picture);
                            }}
                            className="btn btn-light-primary font-weight-bold"
                          >
                            Upload
                          </button> */}
                          <button
                            type="button"
                            onClick={handleRemoveImage}
                            className="btn btn-light-primary font-weight-bold"
                          >
                            Remove
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="w-100">
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    name: "",
                  }}
                  validate={(values) => {
                    {
                      setCreateSoosDataInfoFoam(values);
                    }
                  }}
                  onSubmit={(values) => {
                    {
                    }
                    console.log(
                      "submit",
                      createSoosDataInfoFoam.name,
                      createSoosDataInfoFoam.startDateTime
                    );
                  }}
                >
                  {({ handleSubmit, setFieldValue }) => (
                    <>
                      <Form className="form form-label-right" placeholder={""}>
                        <div className="w-100 mb-4">
                          <Field
                            name="Title"
                            id="Title"
                            component={Input}
                            placeholder="Enter Name"
                            label="Title*"
                            onChange={(e: any) => {
                              // console.log(e.target.value)
                              setTitle(e.target.value);
                            }}
                          />
                        </div>
                        {/* <div className="col-lg-12 mb-4">
                          <span>Description*</span>
                          <textarea
                            rows={3}
                            className="form-control mt-2"
                            placeholder="Enter description"
                            onChange={(e: any) => {
                              // console.log(e.target.value)
                              setShortDetail(e.target.value);
                            }}
                          />
                        </div> */}
                        <div className="w-100 mb-4">
                          {createSoosDataProcessing && (
                            <div className="centered">
                              <CircularProgress className="splash-screen-spinner" />
                            </div>
                          )}
                          <Editor
                            apiKey="37pj686lc9k0marrkw0k6nj986gnykykjq8w8fnzncjl72o2" // Replace with your TinyMCE API key
                            initialValue=""
                            onInit={(evt, editor) => {
                              editorRef.current = editor;
                            }}
                            init={{
                              height: 500,
                              menubar: true,
                              plugins: [
                                "advlist autolink lists link image charmap print preview anchor",
                                "searchreplace visualblocks code fullscreen",
                                "insertdatetime media table paste code help wordcount",
                                "image",
                              ],
                              image_title: true,
                              file_picker_types: "image",
                              /* enable automatic uploads of images represented by blob or data URIs*/
                              automatic_uploads: true,
                              a11y_advanced_options: true,
                              file_picker_callback: (
                                cb: (
                                  blobUri: string,
                                  blobInfo: { title: string }
                                ) => void,
                                value: string,
                                meta: any
                              ) => {
                                const input = document.createElement("input");
                                input.setAttribute("type", "file");
                                input.setAttribute("accept", "image/*");

                                input.onchange = function() {
                                  const file = (this as HTMLInputElement)
                                    .files![0];

                                  const reader = new FileReader();
                                  reader.onload = function() {
                                    const id = "blobid" + new Date().getTime();
                                    const blobCache =
                                      editorRef?.current?.editorUpload
                                        .blobCache;
                                    if (blobCache) {
                                      const base64 = (reader.result as string).split(
                                        ","
                                      )[1];
                                      const blobInfo = blobCache.create(
                                        id,
                                        file,
                                        base64
                                      );
                                      blobCache.add(blobInfo);

                                      cb(blobInfo.blobUri(), {
                                        title: file.name,
                                      });
                                    }
                                  };
                                  reader.readAsDataURL(file);
                                };

                                input.click();
                              },
                              autosave_ask_before_unload: true,
                              autosave_interval: "30s",
                              autosave_prefix: "{path}{query}-{id}-",
                              autosave_restore_when_empty: false,
                              toolbar:
                                "undo redo | formatselect | bold italic backcolor | \
      alignleft aligncenter alignright alignjustify | \
      bullist numlist outdent indent | removeformat | help | image", // Added 'image' to the toolbar
                            }}
                            onEditorChange={handleEditorChange}
                          />
                        </div>
                      </Form>

                      <Modal.Footer style={{ justifyContent: "center" }}>
                        <button
                          type="button"
                          onClick={() => history.push(`/blogs`)}
                          className="btn btn-light-primary font-weight-bold"
                        >
                          Cancel
                        </button>
                        <> </>
                        <button
                          type="submit"
                          onClick={() => {
                            selectedImage
                              ? checkValidation()
                              : addCreateBlogs(
                                  title,
                                  shortDetail,
                                  details,
                                  pictureUrl
                                );
                          }}
                          className="btn btn-light-primary font-weight-bold"
                        >
                          Create
                        </button>
                      </Modal.Footer>
                    </>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default DetailBlogs;
