import React, { Suspense } from "react";
import { Redirect, Switch } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import DashboardPage from "../pages/dashboard/DashboardPage";
import QuizPage from "../pages/quizzes/QuizPage";
import SoosPage from "../pages/soos/SoosPage";
import CreateSoos from "../pages/soos/CreateSoos";
import EditSoos from "../pages/soos/EditSoos";
import BlogsPage from "../pages/blogs/BlogsPage";
import DetailBlogs from "../pages/blogs/DetailBlogs";
import EditBlogs from "../pages/blogs/EditBlogs";
export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <ContentRoute path="/soos" component={SoosPage} />
        <ContentRoute path="/blogs" component={BlogsPage} />
        <ContentRoute path="/create/soos" component={CreateSoos} />
        <ContentRoute path="/create/blogs" component={DetailBlogs} />
        <ContentRoute path="/edit/:id" component={EditSoos} />
        <ContentRoute path="/editBlogs/:id" component={EditBlogs} />
        <ContentRoute path="/quiz/edit/:id/:pageNumber" component={QuizPage} />

        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
