import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as quizActions from "../../redux/reducers/quizzes/quizzesAction";
import { QuizzesList } from "../../components/quizzes/QuizList.component";

const DashboardPage = () => {
  const dispatch = useDispatch();

  const { quizzes } = useSelector(state => ({
    quizzes: state.quizzes.entities
  }));

  useEffect(() => {
    dispatch(quizActions.fetchQuizzes());
  }, [dispatch]);

  return (
    <section>
      <QuizzesList quizzes={quizzes} />
    </section>
  );
};

export default DashboardPage;
