import React, { useEffect, useState, useRef } from "react";
import { useStyles } from "./styles";
import toast, { LoaderIcon, Toaster } from "react-hot-toast";
import { NavLink } from "react-router-dom";
import { useHistory, useParams } from "react-router";
import { Col, Container, Row } from "react-bootstrap";
// import Calendar from "react-input-calendar";
import { Formik, Form, Field, FieldArray } from "formik";
import { Modal } from "react-bootstrap";
import {
  Input,
  Select,
  DatePickerField,
} from "../../_metronic/_partials/controls/";
import { createSoosApi } from "../../redux/reducers/sooses/soosCrud";
import * as Yup from "yup";
import moment from "moment";
import { CircularProgress } from "@material-ui/core";
import { Editor } from "@tinymce/tinymce-react";
import {
  deletePictureApi,
  getBlogsById,
  updateBlogApi,
  uploadPictureApi,
} from "./blogsCrud";

// const questionInitialValues = {
//   answer: ""
// };
const EditBlogs = () => {
  const classes = useStyles();
  const history = useHistory();

  const [createSoosDataProcessing, setCreateSoosDataProcessing] = useState(
    false
  );
  const [title, setTitle] = useState("");
  const [shortDetail, setShortDetail] = useState("");
  const [details, setDetails] = useState("");
  const [initialDetails, setInitialDetails] = useState("");
  const [picture, setPicture] = useState("");
  const [pictureForApi, setPictureForApi] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [pictureUrl, setPictureUrl] = useState("");
  const { id }: any = useParams();
  const editorRef: any = useRef();
  async function getSoosDataBySelectedSoos(item: any) {
    try {
      await getBlogsById(item)
        .then((res: any) => {
          console.log(res, "soosResponse123");
          setTitle(res?.data?.title);
          setShortDetail(res?.data?.shortDetail);
          setInitialDetails(res?.data?.detail);
          setIsActive(res?.data?.isActive);
          setPicture(res?.data?.picture);
        })
        .catch((e: any) => {
          toast.error(e?.response?.data?.message);
        });
    } catch (e) {}
  }
  useEffect(() => {
    getSoosDataBySelectedSoos(id);
    console.log(id, "id");
  }, []);
  const handleEditorChange = (content: any, editor: any) => {
    setDetails(content);
    console.log(content);
  };
  const [selectedImage, setSelectedImage] = useState("");

  const handleImageChange = (e: any) => {
    const file = e.target.files[0];

    if (file) {
      const maxSize = 5000 * 5000;

      if (file.size > maxSize) {
        alert(
          "File size exceeds the 5MB limit. Please choose a smaller image."
        );
        // Clear the input field if the file size is too large
        e.target.value = null;
        return;
      } else {
        setPictureForApi(file);
        const reader = new FileReader();

        reader.onload = (e: any) => {
          setSelectedImage(e.target.result);
        };

        reader.readAsDataURL(file);
      }
    }
  };
  const handleRemoveImage = () => {
    setSelectedImage("");
  };
  useEffect(() => {}, []);

  async function deletePicture(path: any) {
    await deletePictureApi(path)
      .then((res) => {
        console.log("res", res);
        toast.success("Successfully Deleted");
        setPicture("");
        setSelectedImage("");
        setPictureUrl("");
      })
      .catch((e: any) => {
        console.log(e);
        toast.error(e?.response?.data?.message);
      });
  }
  async function uploadPicture(file: any) {
    setCreateSoosDataProcessing(true);
    await uploadPictureApi(file)
      .then((res) => {
        console.log("res", res);

        updateBlogsInfo(
          id,
          title,
          shortDetail,
          details,
          res?.data?.url,
          isActive
        );
      })
      .catch((e) => {
        console.log(e);
        setCreateSoosDataProcessing(false);
        toast.error(e?.response?.data?.message);
      });
  }
  async function updateBlogsInfo(
    item: any,
    title: any,
    shortDetail: any,
    detail: any,
    picture: any,
    isActive: any
  ) {
    await updateBlogApi(item, title, shortDetail, detail, picture, isActive)
      .then((res) => {
        toast.success("Successfully Updated");
        history.push(`/blogs`);
      })
      .catch((e: any) => {
        console.log(e);
        toast.error(e?.response?.data?.message);
      });
  }
  const checkValidation = () => {
    if (title) {
      uploadPicture(pictureForApi);
    } else {
      toast.error("title is required");
    }
  };
  const desiredWidth = 600;
  const desiredHeight = 300;
  return (
    <>
      <Toaster />
      <div className="container-fluid">
        <div className="d-flex justify-content-start">
          <button
            className="btn btn-light-primary font-weight-bold mb-2"
            onClick={() => {
              history.push(`/blogs`);
            }}
          >
            Back
          </button>
        </div>
        <section className="">
          <div className="col-md-12 col-12 d-flex justify-content-center">
            <div className="col-md-8 col-12">
              <div className="form-group form-label-group mb-4">
                {picture != "" ? (
                  <img
                    className="col-12"
                    src={picture}
                    // height="200px"
                    alt=""
                    style={{
                      width: "100%", // Make sure the image takes the full width of its container
                      height: "auto", // Maintain the aspect ratio
                      borderRadius: "5%",
                      objectFit: "contain",
                    }}
                  />
                ) : selectedImage ? (
                  <img
                    className="col-12"
                    src={selectedImage}
                    // height="200px"
                    alt=""
                    style={{
                      width: "100%", // Make sure the image takes the full width of its container
                      height: "auto", // Maintain the aspect ratio

                      borderRadius: "5%",
                      objectFit: "cover",
                    }}
                  />
                ) : pictureUrl != "" ? (
                  <img
                    className="col-12"
                    src={pictureUrl}
                    // height="200px"
                    alt=""
                    style={{
                      width: "100%", // Make sure the image takes the full width of its container
                      height: "auto", // Maintain the aspect ratio

                      borderRadius: "5%",
                      objectFit: "cover",
                    }}
                  />
                ) : (
                  <img
                    className="col-12"
                    src={""}
                    // height="200px"
                    alt=""
                    style={{
                      width: "100%", // Make sure the image takes the full width of its container
                      height: "auto", // Maintain the aspect ratio
                      borderRadius: "5%",
                      objectFit: "cover",
                    }}
                  />
                )}
                {picture == "" && !selectedImage ? (
                  <>
                    <div className="col-12 d-flex justify-content-center">
                      <div className="mt-4">
                        <>
                          <input
                            className="btn btn-light-primary font-weight-bold"
                            type="file"
                            onChange={handleImageChange}
                            accept="image/*"
                          />
                        </>
                        <div className="d-flex">
                          {/* <button
                       type="button"
                       onClick={() => {
                         uploadPicture(picture);
                       }}
                       className="btn btn-light-primary font-weight-bold"
                     >
                       Upload
                     </button> */}
                          {/* <button
                            type="button"
                            onClick={handleRemoveImage}
                            className="btn btn-light-primary font-weight-bold pl-2"
                          >
                            Remove
                          </button> */}
                        </div>
                      </div>
                    </div>
                  </>
                ) : selectedImage && pictureUrl == "" ? (
                  <div className="col-12 d-flex justify-content-center">
                    <div className="mt-4">
                      <div className="d-flex">
                        {/* <button
                          type="button"
                          onClick={() => {
                            uploadPicture(pictureForApi);
                          }}
                          className="btn btn-light-primary font-weight-bold"
                        >
                          Upload
                        </button> */}
                        <button
                          type="button"
                          onClick={handleRemoveImage}
                          className="btn btn-light-primary font-weight-bold pl-2"
                        >
                          Remove
                        </button>
                      </div>
                    </div>
                  </div>
                ) : picture != "" ? (
                  <div className="col-12 d-flex justify-content-center">
                    <div className="mt-4">
                      <div className="d-flex">
                        <button
                          type="button"
                          onClick={() => {
                            deletePicture(picture);
                          }}
                          className="btn btn-light-primary font-weight-bold"
                        >
                          Remove
                        </button>
                      </div>
                    </div>
                  </div>
                ) : pictureUrl != "" ? (
                  <div className="col-12 d-flex justify-content-center">
                    <div className="mt-4">
                      <div className="d-flex">
                        <button
                          type="button"
                          onClick={() => {
                            deletePicture(pictureUrl);
                          }}
                          className="btn btn-light-primary font-weight-bold"
                        >
                          Remove
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {/* <div className="col-12 d-flex justify-content-center">
                  <div className="mt-4">
                    <button
                      type="button"
                      onClick={() => {
                        deletePicture(picture != "" ? picture : pictureUrl);
                      }}
                      className="btn btn-light-primary font-weight-bold"
                    >
                      Remove
                    </button>
                  </div>
                </div>
                <div className="col-12 d-flex justify-content-center">
                  <div className="mt-4">
                    <>
                      <input
                        className="btn btn-light-primary font-weight-bold"
                        type="file"
                        onChange={handleImageChange}
                        accept="image/*"
                      />
                    </>
                    <div className="d-flex">
                      <button
                        type="button"
                        onClick={() => {
                          uploadPicture(picture);
                        }}
                        className="btn btn-light-primary font-weight-bold"
                      >
                        Upload
                      </button>
                      <button
                        type="button"
                        onClick={handleRemoveImage}
                        className="btn btn-light-primary font-weight-bold pl-2"
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                </div> */}
              </div>
              <div className="w-100">
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    Title: title,
                  }}
                  validate={(values) => {
                    {
                    }
                  }}
                  onSubmit={(values) => {
                    {
                    }
                  }}
                >
                  {({ handleSubmit }) => (
                    <>
                      <Form className="form form-label-right" placeholder={""}>
                        <div className="w-100 mb-4">
                          <Field
                            name="Title"
                            component={Input}
                            placeholder="Enter Name"
                            label="Title*"
                            onChange={(e: any) => {
                              // console.log(e.target.value)
                              setTitle(e.target.value);
                            }}
                          />
                        </div>
                        {/* <div className="col-lg-12 mb-4">
                          <span>Description*</span>
                          <textarea
                            rows={3}
                            className="form-control mt-2"
                            placeholder="Enter description"
                            value={shortDetail}
                            onChange={(e: any) => {
                              // console.log(e.target.value)
                              setShortDetail(e.target.value);
                            }}
                          />
                        </div> */}
                        <div className="w-100 mb-4">
                          {createSoosDataProcessing && (
                            <div className="centered">
                              <CircularProgress className="splash-screen-spinner" />
                            </div>
                          )}
                          <Editor
                            apiKey="37pj686lc9k0marrkw0k6nj986gnykykjq8w8fnzncjl72o2" // Replace with your TinyMCE API key
                            initialValue={initialDetails}
                            onInit={(evt, editor) => {
                              editorRef.current = editor;
                            }}
                            init={{
                              height: 500,
                              menubar: true,
                              plugins: [
                                "advlist autolink lists link image charmap print preview anchor",
                                "searchreplace visualblocks code fullscreen",
                                "insertdatetime media table paste code help wordcount",
                                "image",
                              ],
                              image_title: true,
                              file_picker_types: "image",
                              /* enable automatic uploads of images represented by blob or data URIs*/
                              automatic_uploads: true,
                              a11y_advanced_options: true,
                              file_picker_callback: (
                                cb: (
                                  blobUri: string,
                                  blobInfo: { title: string }
                                ) => void,
                                value: string,
                                meta: any
                              ) => {
                                const input = document.createElement("input");
                                input.setAttribute("type", "file");
                                input.setAttribute("accept", "image/*");

                                input.onchange = function() {
                                  const file = (this as HTMLInputElement)
                                    .files![0];

                                  const reader = new FileReader();
                                  reader.onload = function() {
                                    const id = "blobid" + new Date().getTime();
                                    const blobCache =
                                      editorRef?.current?.editorUpload
                                        .blobCache;
                                    if (blobCache) {
                                      const base64 = (reader.result as string).split(
                                        ","
                                      )[1];
                                      const blobInfo = blobCache.create(
                                        id,
                                        file,
                                        base64
                                      );
                                      blobCache.add(blobInfo);

                                      cb(blobInfo.blobUri(), {
                                        title: file.name,
                                      });
                                    }
                                  };
                                  reader.readAsDataURL(file);
                                };

                                input.click();
                              },
                              autosave_ask_before_unload: true,
                              autosave_interval: "30s",
                              autosave_prefix: "{path}{query}-{id}-",
                              autosave_restore_when_empty: false,
                              toolbar:
                                "undo redo | formatselect | bold italic backcolor | \
      alignleft aligncenter alignright alignjustify | \
      bullist numlist outdent indent | removeformat | help | image", // Added 'image' to the toolbar
                            }}
                            onEditorChange={handleEditorChange}
                          />
                        </div>
                      </Form>

                      <Modal.Footer style={{ justifyContent: "center" }}>
                        <button
                          type="button"
                          onClick={() => history.push(`/blogs`)}
                          className="btn btn-light-primary font-weight-bold"
                        >
                          Cancel
                        </button>
                        <> </>
                        <button
                          type="submit"
                          onClick={() => {
                            selectedImage
                              ? checkValidation()
                              : updateBlogsInfo(
                                  id,
                                  title,
                                  shortDetail,
                                  details,
                                  picture != "" ? picture : pictureUrl,
                                  isActive
                                );
                          }}
                          className="btn btn-light-primary font-weight-bold"
                        >
                          Update
                        </button>
                      </Modal.Footer>
                    </>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default EditBlogs;
