import { createUseStyles } from "react-jss";
import { Colors } from "../../uiCore/theme";

export const useStyles = createUseStyles({
  pagination: {
    marginTop: 30,
    marginBottom: 50,
    listStyle: "none",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-evenly",
    margin: 0,
    padding: 0,
    "& li": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginRight: 10,
      marginBottom: 10,
      "& button": {
        background: Colors.themePrimayColor,
        border: 0,
        width: 50,
        height: 50,
        fontSize: 20,
        color: "white",
        textDecoration: "none",
        outline: "none",

        "&.active-page": {
          background: "green"
        },
        "&:hover": {
          cursor: "pointer"
        },
        "&:disabled": {
          background: "#E2E2E2",
          "&:hover": {
            cursor: "diabled"
          }
        }
      },
      "@media screen and (max-width: 1400px)": {
        "& button": {
          width: 50,
          height: 50,
          fontSize: 24
        }
      },
      "@media screen and (max-width: 1200px)": {
        "& button": {
          width: 40,
          height: 40,
          fontSize: 24
        }
      },
      "@media screen and (max-width: 991px)": {
        marginRight: 5,
        "& button": {
          width: 30,
          height: 30,
          fontSize: 18
        }
      },
      "&:last-child": {
        marginRight: 0
      }
    }
  }
});
