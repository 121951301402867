import { createUseStyles } from "react-jss";
import { Colors } from "../../uiCore/theme";

export const useStyles = createUseStyles({
  section: {
    margin: [75, 0]
  },
  title: {
    fontSize: 50,
    position: "relative",
    textAlign: "center",
    paddingBottom: 20,
    marginBottom: 50,
    letterSpacing: 2,
    "&:after": {
      content: '""',
      position: "absolute",
      bottom: 0,
      left: "50%",
      transform: "translateX(-50%)",
      width: 60,
      height: 3,
      background: Colors.themePrimayColor
    }
  },
  imgWrap: {
    "& img": {
      // maxWidth: 800,
      width: "100%"
    },
    "&.right": {
      right: 0,
      top: 0,
      "& img": {
        float: "right"
      }
    },
    "&.left": {
      left: 0,
      top: 0,
      "& img": {
        float: "left"
      }
    }
  },
  secQuiz: {
    position: "relative",
    display: "block",
    "& h2, textarea": {
      fontSize: 20,
      margin: 0,
      letterSpacing: 2,
      padding: 0,
      display: "flex",
      alignItems: "center",
      width: "100%",

      "& span": {
        minWidth: "250px"
      },
      "& textarea": {
        padding: ".5rem"
      }
    },
    "& button": {
      display: "block",
      marginTop: 30
    }
  },
  secEmailSub: {
    background: "#F1F2F4",
    padding: [150, 0],
    marginBottom: 0
  }
});
