import React from "react";
// import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Container, Row, Col } from "react-bootstrap";
import { QuizItem } from "./QuizItem.component";
// import IconCartLight from "../../assets/images/icon-cart-light.svg";
// import { Button } from "../button/button";
// import { useStyles } from "./styles";

export const QuizzesList = ({ quizzes, setQuizzes }: any) => {
  const history = useHistory();

  console.log(quizzes, "quizzes");
  return (
    <Container>
      <Row>
        {quizzes?.length &&
          quizzes?.map(
            (
              item: {
                id: any;
                quizNumber: number;
                name: string | undefined;
                price: string | undefined;
              },
              index: any
            ) => {
              return (
                <Col lg={6} className="px-lg-4">
                  <QuizItem
                    key={index}
                    title={`${item?.name} #${item?.quizNumber}`}
                    price={item?.price}
                    action={"Edit"}
                    onClick={(e: any) => {
                      history.push(`/quiz/edit/${quizzes[index]?.id}/1`);
                    }}
                  />
                </Col>
              );
            }
          )}
      </Row>
    </Container>
  );
};
