import React from "react";
import { useStyles } from "./styles";

interface QuestionTextProps {
  questions?: any;
  question?: string;
  answerVal?: any;
  setAnswerVal?: any;
  statementVal?: any;
  setStatementVal?: any;
  statements?: any;
  setStatements?: any;
  id?: string;
  name?: string;
  key?: string;
  value?: any;
  onChange?: any;
  isQuiz?: boolean;
  setCheckValidation?: any;
}
export const QuizQuestionText = ({
  questions,
  statementVal,
  setStatementVal,
  statements,
  setStatements,
  setCheckValidation,
}: QuestionTextProps) => {
  const classes = useStyles();
  // const [ansForEdit, setAnsForEdit] = useState([...answers]);

  const updateQuestions = (subIndex: any, item: any, text: any) => {
    if (statements && statements.length) {
      statements.splice(subIndex, 1);
      let payload = { ...item, statement: text };
      statements.splice(subIndex, 0, payload);
    }
    setStatements([...statements]);
  };
  const updateAnswer = (subIndex: any, item: any, text: any) => {
    if (statements && statements.length) {
      statements.splice(subIndex, 1);
      let payload = { ...item, key: text };
      console.log(payload);
      setCheckValidation(false);
      statements.splice(subIndex, 0, payload);
    }
    setStatements([...statements]);
  };
  return (
    <ul className={classes.quizQuestions}>
      {questions &&
        questions?.length &&
        questions.map((item: any, index: any) => {
          return (
            <li key={index}>
              <h2>
                <textarea
                  rows={3}
                  className="form-control"
                  placeholder="Enter new question answer"
                  autoFocus={index === 0}
                  id={item?._id}
                  name={item?._id}
                  value={
                    item.statement ? statements[index]?.statement : statementVal
                  }
                  onChange={(e: any) => {
                    updateQuestions(index, item, e.target.value);
                    item.statement
                      ? setStatementVal(statements[index]?.statement)
                      : setStatementVal(item.value);
                  }}
                />
                {/* {item ? item.statement : question} */}
              </h2>
              {/* <hr></hr> */}
              <h3>
                <textarea
                  rows={3}
                  className="form-control"
                  placeholder="Enter your answer"
                  autoFocus={index === 0}
                  id={item?._id}
                  name={item?._id}
                  value={
                    statements[index]?.key == ""
                      ? setCheckValidation(true)
                      : statements[index]?.key
                      ? statements[index]?.key
                      : ""
                  }
                  onChange={(e: any) => {
                    updateAnswer(index, item, e.target.value);
                  }}
                />
              </h3>
            </li>
          );
        })}
    </ul>
  );
};
