import React, { useLayoutEffect, useEffect } from "react";
import { useSelector } from "react-redux";
import { QuickUserToggler } from "../extras/QuiclUserToggler";
import { useLocation } from "react-router-dom";
// import { BreadCrumbs } from "../../components/subheader/components/BreadCrumbs";
import {
  getBreadcrumbsAndTitle,
  useSubheader
} from "../../_core/MetronicSubheader";

export function Topbar() {
  const location = useLocation();
  const subheader = useSubheader();
  const pathNameFirstStr = window.location.pathname.split("/")[1];
  const pathNameSecondStr = window.location.pathname.split("/")[2];

  const { quiz } = useSelector(state => ({
    quiz: state.quizzes.singleQuiz
  }));
  // useEffect(() => {
  //   if (id) {
  //     dispatch(quizActions.fetchQuiz(id));
  //   }
  // }, [dispatch, id]);

  console.log(quiz);

  useLayoutEffect(() => {
    const aside = getBreadcrumbsAndTitle("kt_aside_menu", location.pathname);
    const header = getBreadcrumbsAndTitle("kt_header_menu", location.pathname);
    const breadcrumbs =
      aside && aside.breadcrumbs.length > 0
        ? aside.breadcrumbs
        : header.breadcrumbs;
    // subheader.setBreadcrumbs(breadcrumbs);
    subheader.setTitle(
      aside && aside.title && aside.title.length > 0
        ? aside.title
        : header.title
    );
    console.log(breadcrumbs, "breadcrumbs");
    // eslint-disable-next-line
  }, [location.pathname]);

  // Do not remove this useEffect, need from update title/breadcrumbs outside (from the page)
  useEffect(() => {}, [subheader]);
  return (
    <div className="topbar w-100">
      <div className="d-flex align-items-center mr-5">
        <h1 className="text-capitalize text-dark font-weight-bold my-2 mr-5">
          {pathNameSecondStr ? (
            <span className="text-capitalize">
              {pathNameFirstStr} #{quiz?.quizNumber} -{" "}
              <small className="text-muted text-uppercase">
                {pathNameSecondStr.replace(/-/g, " ")}
              </small>
            </span>
          ) : (
            <span className="text-capitalize">
              {window.location.pathname.split("/")}
            </span>
          )}
          {/* <>{subheader.title}</> */}
          {/*<small></small>*/}
        </h1>

        {/* <BreadCrumbs items={subheader.breadcrumbs} /> */}
      </div>

      <QuickUserToggler />
    </div>
  );
}
