import axios from "axios";

export const GET_QUIZZES_URL = "quizzes";
export const GET_USER_QUIZZES_URL = "userQuiz";

// GET all quizs
export function getAllQuizzes() {
  return axios.get(`${GET_QUIZZES_URL}?isFrom=admin&sortBy=createdAt:asc`);
}
// GET single quiz by id
export function getQuizById(id: any) {
  return axios.get(`${GET_QUIZZES_URL}/${id}?isFrom=admin`);
}

// PATCH questions
export function updateQuestion(
  id: any,
  payload: { questionId: any; statement: any; parts: any[] }
) {
  return axios.patch(`${GET_QUIZZES_URL}/${id}`, payload);
}
