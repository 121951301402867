import React, { useEffect, useState } from "react";
import { useStyles } from "./styles";
import toast, { LoaderIcon, Toaster } from "react-hot-toast";
import { NavLink } from "react-router-dom";
import { useHistory, useParams } from "react-router";
import { Col, Container, Row } from "react-bootstrap";
// import Calendar from "react-input-calendar";
import { Formik, Form, Field, FieldArray } from "formik";
import { Modal } from "react-bootstrap";
import {
  Input,
  Select,
  DatePickerField,
} from "../../_metronic/_partials/controls/";
import {
  createSoosApi,
  getSoosById,
  updateSoosApi,
} from "../../redux/reducers/sooses/soosCrud";
import * as Yup from "yup";
import moment from "moment";
import { CircularProgress } from "@material-ui/core";

// const questionInitialValues = {
//   answer: ""
// };
const EditSoos = () => {
  const classes = useStyles();
  const history = useHistory();
  const [updateSoosDataProcessing, setUpdateSoosDataProcessing] = useState(
    false
  );
  const [soosEditName, setSoosEditName] = useState<any>();
  const [soosEditPrice, setSoosEditPrice] = useState();
  const [soosEditTotalCount, setSoosEditTotalCount] = useState();
  const [soosEditStartDate, setSoosEditStartDate] = useState<any>();
  const [soosEditEndDate, setSoosEditEndDate] = useState<any>();
  const [soosEditTimeOfDay, setSoosEditTimeOfDay] = useState<any>();
  const [soosEditRegistrationDate, setSoosEditRegistrationDate] = useState<
    any
  >();
  const [editSoosDataInfoFoam, setEditSoosDataInfoFoam] = useState<any>();
  const { id }: any = useParams();
  function getDateUtcIntoLocal(date: any, formate: any) {
    if (date) {
      return moment
        .utc(date)
        .local()
        .format(formate);
    }
  }
  async function getSoosDataBySelectedSoos(item: any) {
    try {
      await getSoosById(item)
        .then((res: any) => {
          setSoosEditName(res?.data.name);
          setSoosEditPrice(res?.data.price);
          setSoosEditTotalCount(res?.data.totalCount);
          setSoosEditStartDate(
            getDateUtcIntoLocal(res?.data.startDateTime, "MM/DD/yyyy h:mm A")
          );
          setSoosEditEndDate(
            getDateUtcIntoLocal(res?.data.endDateTime, "MM/DD/yyyy h:mm A")
          );
          setSoosEditTimeOfDay(res?.data.timeOfDay);
          setSoosEditRegistrationDate(
            getDateUtcIntoLocal(
              res?.data.registrationEndTime,
              "MM/DD/yyyy h:mm A"
            )
          );
          console.log(res, "soosResponse123");
        })
        .catch((e: any) => {
          toast.error(e?.response?.data?.message);
        });
    } catch (e) {}
  }
  useEffect(() => {
    getSoosDataBySelectedSoos(id);
    console.log(id, "id");
  }, []);

  const CustomerEditSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Name is required"),
    price: Yup.number()
      .min(0, "Minimum 0 number")
      .required("Amount is required"),
    totalCount: Yup.number()
      .min(0, "Minimum 0 number")
      .required("Total Count is required"),
    endDateTime: Yup.mixed()
      // .nullable()
      .required("End Date Time is required"),
    startDateTime: Yup.string()
      .when("endDateTime", ([endDateTime, startDateTime], schema) => {
        if (endDateTime < startDateTime)
          return Yup.string().required(
            "startDateTime should less than endDateTime"
          );
        return schema;
      })
      // .nullable(false)
      .required("Start Date Time is required"),

    timeofDay: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Time of Day is required"),
    registrationEndTime: Yup.mixed()
      // .nullable(false)
      .required("Registration End Time is required"),
  });
  function getDatelocalIntoUtc(date: any, format: any) {
    if (date) {
      return moment(date)
        .utc()
        .format();
    }
  }
  async function updateSoosInfo(
    item: any,
    name: any,
    price: any,
    totalCount: any,
    startDateTime: any,
    endDateTime: any,
    timeofDay: any,
    registrationEndTime: any
  ) {
    setUpdateSoosDataProcessing(true);
    await updateSoosApi(
      item,
      name,
      price,
      totalCount,
      startDateTime,
      endDateTime,
      timeofDay,
      registrationEndTime
    )
      .then((res) => {
        console.log("res", res);
        setUpdateSoosDataProcessing(false);
        toast.success("Successfully Updated");
        history.push(`/soos`);
      })
      .catch((e) => {
        console.log(e);
        setUpdateSoosDataProcessing(false);
        toast.error(e?.response?.data?.message);
      });
  }
  function checkValidationForEditSoos() {
    if (
      editSoosDataInfoFoam.startDateTime >=
        editSoosDataInfoFoam.registrationEndTime &&
      editSoosDataInfoFoam.startDateTime < editSoosDataInfoFoam.endDateTime
    ) {
      console.log("hello");
      updateSoosInfo(
        id,
        editSoosDataInfoFoam.name,
        editSoosDataInfoFoam.price,
        editSoosDataInfoFoam.totalCount,
        getDatelocalIntoUtc(
          editSoosDataInfoFoam.startDateTime,
          "yyyy-MM-ddTHH:mm:00.000Z"
        ),
        getDatelocalIntoUtc(
          editSoosDataInfoFoam.endDateTime,
          "yyyy-MM-ddTHH:mm:00.000Z"
        ),
        editSoosDataInfoFoam.timeofDay,
        getDatelocalIntoUtc(
          editSoosDataInfoFoam.registrationEndTime,
          "yyyy-MM-ddTHH:mm:00.000Z"
        )
      );
    } else if (
      editSoosDataInfoFoam.startDateTime <
      editSoosDataInfoFoam.registrationEndTime
    ) {
      toast.error(
        "Registration Date Time should less than or equal to Start Date Time"
      );
    } else if (
      editSoosDataInfoFoam.endDateTime <= editSoosDataInfoFoam.startDateTime
    ) {
      toast.error("Start Date Time should less than the End Date Time");
    } else {
      toast.error(
        "Please check the Start Date Time & End Date Time & Registration Date Time values"
      );
    }
  }
  return (
    <>
      <Toaster />
      <div className="container-fluid">
        <div className="d-flex justify-content-start">
          <button
            className="btn btn-light-primary font-weight-bold mb-2"
            onClick={() => {
              history.push(`/soos`);
            }}
          >
            Back
          </button>
        </div>
        <section>
          <Formik
            enableReinitialize={true}
            initialValues={{
              name: soosEditName,
              price: soosEditPrice,
              totalCount: soosEditTotalCount,
              timeofDay: soosEditTimeOfDay,
              startDateTime: soosEditStartDate,
              endDateTime: soosEditEndDate,
              registrationEndTime: soosEditRegistrationDate,
            }}
            validationSchema={CustomerEditSchema}
            validate={(values) => {
              {
                setEditSoosDataInfoFoam(values);
              }
            }}
            onSubmit={(values) => {
              {
                checkValidationForEditSoos();
              }
              {
                console.log(editSoosDataInfoFoam.name, "name");
                console.log(
                  editSoosDataInfoFoam.startDateTime,
                  "startDateTime"
                );
                console.log(editSoosDataInfoFoam.endDateTime, "endDateTime");
              }
            }}
          >
            {({ handleSubmit }) => (
              <>
                <Modal.Body className="overlay overlay-block cursor-default">
                  <Form className="form form-label-right" placeholder={""}>
                    {updateSoosDataProcessing && (
                      <div className="centered">
                        <CircularProgress className="splash-screen-spinner" />
                      </div>
                    )}
                    <div className="form-group row">
                      <div className="col-lg-6 mb-4">
                        <Field
                          type="text"
                          name="name"
                          showTimeSelect={true}
                          // value={soosEditName}
                          component={Input}
                          label="Name*"
                          // onChange={(event: any) => {
                          //   setSoosEditName(event.target.value);
                          // }}
                        />
                      </div>
                      <div className="col-lg-6 mb-4">
                        <Field
                          type="text"
                          name="price"
                          // value={soosEditPrice}
                          component={Input}
                          // onChange={(event: any) => {
                          //   setSoosEditPrice(event.target.value);
                          // }}
                          label="Amount*"
                        />
                      </div>
                      <div className="col-lg-6 mb-4">
                        <Field
                          type="text"
                          name="totalCount"
                          label="Available Count*"
                          // value={soosEditTotalCount}
                          component={Input}
                          // onChange={(event: any) => {
                          //   setSoosEditTotalCount(event.target.value);
                          // }}
                        />
                      </div>
                      <div className="col-lg-6 mb-4">
                        <Field
                          // value={soosEditTimeOfDay}
                          name="timeofDay"
                          component={Input}
                          label="Time Of Day*"
                          // onChange={(event: any) => {
                          //   setSoosEditTimeOfDay(event.target.value);
                          // }}
                        />
                      </div>
                      <div className="col-lg-6 mb-4 date-picker-wrapper">
                        <DatePickerField
                          // selected={}
                          name="startDateTime"
                          label="Start Date Time*"
                          // placeholder={soosEditStartDate}
                          showTimeSelect={true}
                          dateFormat="MM/dd/yyyy h:mm a"
                          // onChange={(event: any) => {
                          //   setSoosEditStartDate(event.target.value);
                          // }}
                        />
                      </div>
                      <div className="col-lg-6 mb-4 date-picker-wrapper">
                        <DatePickerField
                          // selected={soosEditEndDate}
                          name="endDateTime"
                          label="End Date Time*"
                          showTimeSelect={true}
                          dateFormat="MM/dd/yyyy h:mm a"
                          // onChange={(event: any) => {
                          //   setSoosEditEndDate(event.target.value);
                          // }}
                        />
                      </div>
                      <div className="col-lg-6 mb-4 date-picker-wrapper">
                        <DatePickerField
                          // selected={soosEditRegistrationDate}
                          name="registrationEndTime"
                          label="Registration End Time*"
                          showTimeSelect={true}
                          dateFormat="MM/dd/yyyy h:mm a"
                          // onChange={(event: any) => {
                          //   setSoosEditRegistrationDate(event.target.value);
                          // }}
                        />
                      </div>
                    </div>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    type="button"
                    onClick={() => {
                      history.push(`/soos`);
                    }}
                    className="btn btn-light-primary font-weight-bold mr-2"
                  >
                    Cancel
                  </button>
                  <> </>
                  <button
                    type="submit"
                    onClick={() => {
                      handleSubmit();
                      // checkValidationForEditSoos();
                    }}
                    className="btn btn-light-primary font-weight-bold"
                  >
                    Update
                  </button>
                </Modal.Footer>
              </>
            )}
          </Formik>
        </section>
      </div>
    </>
  );
};

export default EditSoos;
