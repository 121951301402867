import React, { useEffect, useState } from "react";
import { useStyles } from "./styles";
import {
  deleteSooses,
  getAllSooses,
  getSoosById,
  updateSoosApi,
} from "../../redux/reducers/sooses/soosCrud";
import moment from "moment";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import trash from "../../../src/assets/images/trash.png";
import edit from "../../../src/assets/images/editing.png";
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import toast, { Toaster } from "react-hot-toast";
import { NavLink } from "react-router-dom";
import { useHistory } from "react-router";
import { string } from "prop-types";
import { Input, DatePickerField } from "../../_metronic/_partials/controls";
import { Formik, Form, Field, FieldArray } from "formik";
import * as Yup from "yup";
import { Modal } from "react-bootstrap";
// const questionInitialValues = {
//   answer: ""
// };

const SoosPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const [soosTableData, setSoosTableData] = useState([]);
  const [openTrashDialogBox, setOpenTrashDialogBox] = useState(false);
  const [openEditDialogBox, setOpenEditDialogBox] = useState(false);
  const [openFilterDialogBox, setOpenFilterDialogBox] = useState(false);
  const [sooDeleteId, setSooDeleteId] = useState();
  const [page, setPage] = useState(1);
  const [dataLimit, setDataLimit] = useState(8);
  const [searchByDate, setSearchByDate] = useState<string>();
  const [filterSoosDataInfoFoam, setFilterSoosDataInfoFoam] = useState<any>();
  const [createSoosDataInfoFoam, setCreateSoosDataInfoFoam] = useState<any>();
  const [startOfDay, setStartOfDay] = useState<any>("");
  const [endOfDay, setEndOfDay] = useState<any>("");
  const [startDate, setStartDate] = useState<any>("");
  const [endDate, setEndDate] = useState<any>("");

  useEffect(() => {
    console.log(soosTableData, "soos");
    // getSoosesData(page);
    setTimeout(() => {
      setPage(1);
      getSoosesData(page, "", "");
    }, 1500);
  }, []);
  async function resetFilter() {
    try {
      setStartOfDay("");
      setEndOfDay("");
      setStartDate("");
      setEndDate("");
      getSoosesData(1, "", "");
    } catch (e) {}
  }
  async function getSoosesData(
    page: any,
    startOfDay: string,
    endOfDay: string
  ) {
    try {
      let res = await getAllSooses(page, startOfDay, endOfDay);
      if (res) {
        setPage(page);
        console.log(res, "soosres");
        setSoosTableData(res?.data?.results);
      }
    } catch (e) {}
  }
  async function deleteSelectedSosses(item: any) {
    try {
      await deleteSooses(item)
        .then((res: any) => {
          getSoosesData(page, startOfDay, endOfDay);
          toast.success("Deleted successfully");
        })
        .catch((e: any) => {
          toast.error(e?.response?.data?.message);
        });
    } catch (e) {}
  }
  function getDatelocalIntoUtc(date: any, format: any) {
    if (date) {
      return moment(date)
        .utc()
        .format();
    }
  }
  function getDateUtcIntoLocal(date: any, formate: any) {
    if (date) {
      return moment
        .utc(date)
        .local()
        .format(formate);
    }
  }
  const handleClickToOpenTrashDialogBox = (item: any) => {
    setOpenTrashDialogBox(true);
    setSooDeleteId(item);
  };
  const handleToClose = () => {
    setOpenTrashDialogBox(false);
  };
  const handleToOpenFilterDialog = () => {
    setOpenFilterDialogBox(true);
  };
  const handleToCloseFilterDialog = () => {
    setOpenFilterDialogBox(false);
  };

  const setPagination = () => {
    return (
      <div className="d-flex justify-content-end">
        {page > 1 && (
          <div
            onClick={() => {
              setPage(page - 1);
              getSoosesData(page - 1, startOfDay, endOfDay);
            }}
          >
            <div className="cursor-pointer text-primary">Prev</div>
          </div>
        )}

        <div className="text-info pl-2 pr-2">{page}</div>
        {soosTableData.length === dataLimit && (
          <div
            className="cursor-pointer text-primary"
            onClick={() => {
              setPage(page + 1);
              getSoosesData(page + 1, startOfDay, endOfDay);
            }}
          >
            Next
          </div>
        )}
      </div>
    );
  };
  const CustomerEditSchema = Yup.object().shape({
    startDate: Yup.mixed()
      // .nullable()
      .required("Start Date is required"),
    endDate: Yup.mixed()
      // .nullable()
      .required("End Date is required"),
  });
  async function checkValidationForFilterSoos() {
    if (filterSoosDataInfoFoam.endDate >= filterSoosDataInfoFoam.startDate) {
      setStartDate(filterSoosDataInfoFoam.startDate);
      setEndDate(filterSoosDataInfoFoam.endDate);
      let startDateTime = getDatelocalIntoUtc(
        moment(filterSoosDataInfoFoam.startDate).startOf("day"),
        "yyyy-MM-ddTHH:mm:00.000Z"
      );
      let endDateTime = getDatelocalIntoUtc(
        moment(filterSoosDataInfoFoam.endDate).endOf("day"),
        "yyyy-MM-ddTHH:mm:00.000Z"
      );
      setStartOfDay(startDateTime);

      setEndOfDay(endDateTime);
      try {
        let res = await getAllSooses(1, startDateTime, endDateTime);
        if (res) {
          setPage(1);
          setSoosTableData(res?.data?.results);
          setOpenFilterDialogBox(false);
          console.log(res, "filter");
        }
      } catch (e) {}
    } else if (
      filterSoosDataInfoFoam.startDate > filterSoosDataInfoFoam.endDate
    ) {
      toast.error("Start Date should less than the End Date");
    }
  }
  return (
    <>
      <Toaster />
      <div className="container-fluid">
        <div className="d-flex justify-content-end">
          <div className="d-flex pr-2">
            <button
              className="btn btn-light-primary font-weight-bold mb-2"
              type="button"
              onClick={handleToOpenFilterDialog}
            >
              Filter
            </button>
          </div>
          <div className="d-flex pr-2">
            <button
              className="btn btn-light-primary font-weight-bold mb-2"
              type="button"
              onClick={() => {
                resetFilter();
              }}
            >
              Reset
            </button>
          </div>
          <button
            className="btn btn-light-primary font-weight-bold mb-2"
            onClick={() => {
              history.push(`/create/soos`);
            }}
          >
            Create Soo
          </button>
        </div>
        <Dialog open={openFilterDialogBox} onClose={handleToClose}>
          <DialogTitle>{"Filter"}</DialogTitle>
          <DialogContent>
            <section>
              <Formik
                enableReinitialize={true}
                initialValues={{ startDate: startDate, endDate: endDate }}
                validationSchema={CustomerEditSchema}
                validate={(values) => {
                  {
                    setFilterSoosDataInfoFoam(values);
                  }
                }}
                onSubmit={(values) => {
                  {
                    checkValidationForFilterSoos();
                  }
                  console.log(
                    "submit",
                    filterSoosDataInfoFoam.startDate,
                    filterSoosDataInfoFoam.endDate
                  );
                }}
              >
                {({ handleSubmit }) => (
                  <>
                    <Modal.Body className="overlay overlay-block cursor-default">
                      <Form className="form form-label-right" placeholder={""}>
                        <div className="form-group row">
                          <div className="col-lg-6 mb-4 date-picker-wrapper">
                            <DatePickerField
                              name="startDate"
                              label="Start Date*"
                              showTimeSelect={false}
                              dateFormat="MM/dd/yyyy"
                            />
                          </div>
                          <div className="col-lg-6 mb-4 date-picker-wrapper">
                            <DatePickerField
                              name="endDate"
                              label="End Date*"
                              showTimeSelect={false}
                              dateFormat="MM/dd/yyyy"
                            />
                          </div>
                        </div>
                      </Form>
                    </Modal.Body>
                    <Modal.Footer>
                      <button
                        type="button"
                        onClick={handleToCloseFilterDialog}
                        className="btn btn-light-primary font-weight-bold"
                      >
                        Cancel
                      </button>
                      <> </>
                      <button
                        type="submit"
                        onClick={() => {
                          handleSubmit();
                        }}
                        className="btn btn-light-primary font-weight-bold"
                      >
                        Find
                      </button>
                    </Modal.Footer>
                  </>
                )}
              </Formik>
            </section>
          </DialogContent>
        </Dialog>
        <div className="">
          <form>
            <table className="table table-striped table-hover text-center fs-5 table-boardered">
              <thead className="table-primary">
                <tr>
                  <th id="tr">Name</th>
                  <th id="tr">Price</th>
                  <th id="tr">Date</th>
                  <th id="tr">Time</th>
                  <th id="tr">Sold</th>
                  <th id="tr">Actions</th>
                </tr>
              </thead>
              <tbody>
                {soosTableData?.map(
                  (
                    item: {
                      id: any;
                      startDateTime: any;
                      endDateTime: any;
                      timeOfDay: any;
                      count: any;
                      name: string | undefined;
                      price: string | undefined;
                      registrationEndTime: any;
                    },
                    index
                  ) => {
                    return (
                      <>
                        <tr key={index}>
                          <td>{item.name}</td>
                          <td>{item.price}</td>
                          <td>
                            {getDateUtcIntoLocal(
                              item?.startDateTime,
                              "dddd, MMMM Do yyyy"
                            )}
                          </td>
                          <td>
                            {`${item?.timeOfDay}`}
                            <br />
                            {`${getDateUtcIntoLocal(
                              item?.startDateTime,
                              "h:mm a"
                            )}-${getDateUtcIntoLocal(
                              item?.endDateTime,
                              "h:mm a"
                            )}`}
                          </td>
                          <td>{item.count}</td>
                          <td>
                            <Dialog
                              open={openTrashDialogBox}
                              onClose={handleToClose}
                            >
                              <DialogTitle>
                                {"Are you sure you want to delete?"}
                              </DialogTitle>
                              <DialogContent>
                                <div className="d-flex justify-content-around">
                                  <button
                                    className="btn btn-light-primary font-weight-bold mb-2"
                                    onClick={handleToClose}
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    className="btn btn-light-primary font-weight-bold mb-2"
                                    onClick={() => {
                                      deleteSelectedSosses(sooDeleteId);
                                      handleToClose();
                                    }}
                                  >
                                    Delete
                                  </button>
                                </div>
                              </DialogContent>
                            </Dialog>
                            <img
                              className="cursor-pointer"
                              width={18}
                              height={18}
                              src={trash}
                              alt=""
                              onClick={() => {
                                handleClickToOpenTrashDialogBox(item.id);
                              }}
                            />
                            <img
                              className="cursor-pointer"
                              width={18}
                              height={16}
                              src={edit}
                              alt=""
                              onClick={() => {
                                history.push(`/edit/${item.id}`);
                              }}
                            />
                          </td>
                        </tr>
                      </>
                    );
                  }
                )}
              </tbody>
            </table>
            {setPagination()}
          </form>
        </div>
      </div>
    </>
  );
};

export default SoosPage;
