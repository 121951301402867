import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import * as quizActions from "../../redux/reducers/quizzes/quizzesAction";
import { Button, Pagination, QuizQuestionText } from "../../components";
import { useStyles } from "./styles";
import toast, { Toaster } from "react-hot-toast";

// const questionInitialValues = {
//   answer: ""
// };

const QuizPage = () => {
  const { quiz } = useSelector((state: any) => ({
    quiz: state.quizzes.singleQuizQuestions,
  }));

  const { id, pageNumber }: any = useParams();
  const dispatch: any = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const [partStatementVal, setPartStatementVal] = useState<any>();
  const [partStatementScore, setPartStatementScore] = useState<any>();
  const [statements, setStatements] = useState<any>([]);
  const [statementVal, setStatementVal] = useState<any>("");
  const [currPage, setCurrPage] = useState<any>(1);
  const [activePage, setActivePage] = useState<any>(pageNumber);
  const [totalPages, setTotalPages] = useState<any>();
  const [checkValidation, setCheckValidation] = useState(false);

  useEffect(() => {
    if (quiz && quiz[pageNumber - 1]) {
      setPartStatementVal(quiz[pageNumber - 1].statement);
      setPartStatementScore(quiz[pageNumber - 1].score);

      setTotalPages(quiz?.length);
      setStatements([...quiz[pageNumber - 1].parts]);
    }
  }, [pageNumber, quiz]);

  useEffect(() => {
    if (id) {
      dispatch(quizActions.fetchQuiz(id));
    }
  }, [dispatch, id, pageNumber]);

  const submitUpdatedQuestions = (data: any) => {
    console.log(checkValidation, "checkValidation");
    if (!checkValidation) {
      let payload = {
        questionId: quiz[pageNumber - 1]._id,
        statement: partStatementVal,
        score: partStatementScore,
        parts: [...statements],
      };
      // debugger;
      dispatch(quizActions.saveQuizQuestion(id, payload))
        .then(() => {
          window.scrollTo(0, 0);
          if (currPage === totalPages) {
            history.push(`/dashboard`);
            dispatch(quizActions.fetchQuiz(id));
            // toast.success("Quiz updated successfully");
          } else {
            setCurrPage(currPage + 1);
            history.push(`/quiz/edit/${id}/${currPage + 1}`);
            // toast.success("Question updated successfully");
          }
        })
        .catch((error: any) => {
          error.clientMessage = "Can't find users";
          toast.error(error?.response?.data?.message);
        });
    } else {
      toast.error("Answer Fields required");
    }
  };

  const handleInputChange = (event: any) => {
    const numericValue = event.target.value.replace(/[^0-9]/g, "");
    event.target.value = numericValue;
  };
  return (
    <>
      <Toaster />
      <section className={`quiz-page  ${classes.secQuiz}`}>
        <form
          autoComplete="false"
          // onSubmit={(data) => submitUpdatedQuestions(data)}
        >
          <h2>
            <span>Question {pageNumber}:</span>
            <textarea
              rows={3}
              className="form-control"
              placeholder="Enter new question answer"
              name={quiz?.length && quiz[pageNumber - 1]?._id}
              value={partStatementVal}
              onChange={(e: any) => {
                setPartStatementVal(e.target.value);
              }}
            />
          </h2>

          <h3 style={{ paddingTop: "5px" }}>
            <span>Score:</span>
            <input
              type="text"
              className="custom-input"
              placeholder="Enter Score"
              onInput={handleInputChange}
              value={partStatementScore ? partStatementScore : ""}
              onChange={(e: any) => {
                setPartStatementScore(e.target.value);
              }}
            />
          </h3>
          <Pagination
            totalPages={totalPages}
            currPage={pageNumber}
            activePage={activePage}
            onClick={(index: any) => {
              setCurrPage(index);
              history.push(`/quiz/edit/${id}/${index}`);
            }}
            onChange={(index: any) => setActivePage(index)}
          />
          <QuizQuestionText
            questions={quiz && quiz[pageNumber - 1]?.parts}
            isQuiz={true}
            statements={statements}
            setStatements={setStatements}
            statementVal={statementVal}
            setStatementVal={setStatementVal}
            setCheckValidation={setCheckValidation}
          />
          <Button
            variant={"primary"}
            text={currPage === totalPages ? "Publish" : "Next Question/Save"}
            className={"text-center"}
            // type="submit"
            onClick={(data: any) => submitUpdatedQuestions(data)}
            // to={`/quiz/edit/${id}/${currPage + 1}`}
          />
          {/* {currPage === totalPages ? "Publish" : "Next Question/Save"} */}
          {/* </Button> */}
        </form>
      </section>
    </>
  );
};

export default QuizPage;
