import React, { useEffect, useState } from "react";
import { useStyles } from "./styles";
import toast, { LoaderIcon, Toaster } from "react-hot-toast";
import { NavLink } from "react-router-dom";
import { useHistory } from "react-router";
import { Col, Container, Row } from "react-bootstrap";
// import Calendar from "react-input-calendar";
import { Formik, Form, Field, FieldArray } from "formik";
import { Modal } from "react-bootstrap";
import {
  Input,
  Select,
  DatePickerField,
} from "../../_metronic/_partials/controls/";
import { createSoosApi } from "../../redux/reducers/sooses/soosCrud";
import * as Yup from "yup";
import moment from "moment";
import { CircularProgress } from "@material-ui/core";

// const questionInitialValues = {
//   answer: ""
// };
const CreateSoos = () => {
  const classes = useStyles();
  const history = useHistory();
  const [createSoosDataInfoFoam, setCreateSoosDataInfoFoam] = useState<any>();
  const [createSoosDataProcessing, setCreateSoosDataProcessing] = useState(
    false
  );
  useEffect(() => {}, []);

  async function addCreateSoosInfo(
    name: any,
    price: any,
    totalCount: any,
    startDateTime: any,
    endDateTime: any,
    timeofDay: any,
    registrationEndTime: any
  ) {
    setCreateSoosDataProcessing(true);
    await createSoosApi(
      name,
      price,
      totalCount,
      startDateTime,
      endDateTime,
      timeofDay,
      registrationEndTime
    )
      .then((res) => {
        console.log("res", res);
        setCreateSoosDataProcessing(false);
        toast.success("Successfully Created");
        history.push(`/soos`);
      })
      .catch((e) => {
        console.log(e);
        setCreateSoosDataProcessing(false);
        toast.error(e?.response?.data?.message);
      });
  }
  const CustomerEditSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Name is required"),
    price: Yup.number()
      .min(0, "Minimum 0 number")
      .required("Amount is required"),
    totalCount: Yup.number()
      .min(0, "Minimum 0 number")
      .required("Total Count is required"),
    startDateTime: Yup.mixed().required("Start Date Time is required"),
    endDateTime: Yup.mixed().required("End Date Time is required"),
    timeofDay: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Time of Day is required"),
    registrationEndTime: Yup.mixed().required(
      "Registration End Time is required"
    ),
  });
  function getDatelocalIntoUtc(date: any, format: any) {
    if (date) {
      return moment(date)
        .utc()
        .format();
    }
  }
  function checkValidationForCreateSoo() {
    if (
      createSoosDataInfoFoam.startDateTime >=
        createSoosDataInfoFoam.registrationEndTime &&
      createSoosDataInfoFoam.startDateTime < createSoosDataInfoFoam.endDateTime
    ) {
      addCreateSoosInfo(
        createSoosDataInfoFoam.name,
        createSoosDataInfoFoam.price,
        createSoosDataInfoFoam.totalCount,
        getDatelocalIntoUtc(
          createSoosDataInfoFoam.startDateTime,
          "yyyy-MM-ddTHH:mm:00.000Z"
        ),
        getDatelocalIntoUtc(
          createSoosDataInfoFoam.endDateTime,
          "yyyy-MM-ddTHH:mm:00.000Z"
        ),
        createSoosDataInfoFoam.timeofDay,
        getDatelocalIntoUtc(
          createSoosDataInfoFoam.registrationEndTime,
          "yyyy-MM-ddTHH:mm:00.000Z"
        )
      );
    } else if (
      createSoosDataInfoFoam.startDateTime == "" ||
      createSoosDataInfoFoam.endDateTime == "" ||
      createSoosDataInfoFoam.registrationEndTime == "" ||
      createSoosDataInfoFoam.name == "" ||
      createSoosDataInfoFoam.price == "" ||
      createSoosDataInfoFoam.timeofDay == ""
    ) {
      toast.error("All fields are required");
    } else if (
      createSoosDataInfoFoam.registrationEndTime >
      createSoosDataInfoFoam.startDateTime
    ) {
      toast.error(
        "Registration Date Time should less than or equal to Start Date Time"
      );
    } else if (
      createSoosDataInfoFoam.endDateTime < createSoosDataInfoFoam.startDateTime
    ) {
      toast.error("Start Date Time should less than the End Date Time");
    }
  }
  return (
    <>
      <Toaster />
      <div className="container-fluid">
        <div className="d-flex justify-content-start">
          <button
            className="btn btn-light-primary font-weight-bold mb-2"
            onClick={() => {
              history.push(`/soos`);
            }}
          >
            Back
          </button>
        </div>
        <section>
          <Formik
            enableReinitialize={true}
            initialValues={{
              name: "",
              startDateTime: "",
              price: "",
              endDateTime: "",
              timeofDay: "",
              registrationEndTime: "",
              totalCount: "",
            }}
            validationSchema={CustomerEditSchema}
            validate={(values) => {
              {
                setCreateSoosDataInfoFoam(values);
              }
            }}
            onSubmit={(values) => {
              {
                checkValidationForCreateSoo();
              }
              console.log(
                "submit",
                createSoosDataInfoFoam.name,
                createSoosDataInfoFoam.startDateTime,
                createSoosDataInfoFoam.price,
                createSoosDataInfoFoam.endDateTime,
                createSoosDataInfoFoam.timeofDay,
                createSoosDataInfoFoam.registrationEndTime,
                createSoosDataInfoFoam.totalCount
              );
            }}
          >
            {({ handleSubmit }) => (
              <>
                <Modal.Body className="overlay overlay-block cursor-default">
                  <Form className="form form-label-right" placeholder={""}>
                    {createSoosDataProcessing && (
                      <div className="centered">
                        <CircularProgress className="splash-screen-spinner" />
                      </div>
                    )}
                    <div className="form-group row">
                      <div className="col-lg-6 mb-4">
                        <Field
                          name="name"
                          component={Input}
                          placeholder="Enter Name"
                          label="Name*"
                        />
                      </div>
                      <div className="col-lg-6 mb-4">
                        <Field
                          name="price"
                          component={Input}
                          placeholder="Enter Amount"
                          label="Amount*"
                        />
                      </div>
                      <div className="col-lg-6 mb-4">
                        <Field
                          name="timeofDay"
                          component={Input}
                          placeholder="Enter Time of Day"
                          label="Time Of Day*"
                        />
                      </div>
                      <div className="col-lg-6 mb-4">
                        <Field
                          name="totalCount"
                          component={Input}
                          placeholder="Enter totalCount"
                          label="Available Count*"
                        />
                      </div>
                      <div className="col-lg-6 mb-4 date-picker-wrapper">
                        <DatePickerField
                          name="startDateTime"
                          label="Start Date Time*"
                          showTimeSelect={true}
                          dateFormat="MM/dd/yyyy h:mm a"
                        />
                      </div>
                      <div className="col-lg-6 mb-4 date-picker-wrapper">
                        <DatePickerField
                          name="endDateTime"
                          label="End Date Time*"
                          showTimeSelect={true}
                          dateFormat="MM/dd/yyyy h:mm a"
                        />
                      </div>
                      <div className="col-lg-6 mb-4 date-picker-wrapper">
                        <DatePickerField
                          name="registrationEndTime"
                          label="Registration End Time*"
                          showTimeSelect={true}
                          dateFormat="MM/dd/yyyy h:mm a"
                        />
                      </div>
                    </div>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    type="button"
                    onClick={() => history.push(`/soos`)}
                    className="btn btn-light-primary font-weight-bold"
                  >
                    Cancel
                  </button>
                  <> </>
                  <button
                    type="submit"
                    onClick={() => {
                      handleSubmit();
                    }}
                    className="btn btn-light-primary font-weight-bold"
                  >
                    Create
                  </button>
                </Modal.Footer>
              </>
            )}
          </Formik>
        </section>
      </div>
    </>
  );
};

export default CreateSoos;
