// import toast from "react-hot-toast";
import * as requestFromServer from "./quizzesCrud";
import { quizzesSlice, callTypes } from "./quizzesSlice";

const { actions } = quizzesSlice;

export const fetchQuizzes = () => (dispatch: any) => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  return requestFromServer
    .getAllQuizzes()
    .then((response: { data: { results: any } }) => {
      const data = response.data.results;
      dispatch(
        actions.quizzesFetched({
          entities: data
        })
      );
    })
    .catch((error: { clientMessage: string }) => {
      error.clientMessage = "Can't find quizzes";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchQuiz = (id: any) => (dispatch: (arg0: any) => void) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getQuizById(id)
    .then((response: { data: any }) => {
      const data = response?.data;
      dispatch(
        actions.quizFetched({
          singleQuiz: data,
          singleQuizQuestions: data?.questions
        })
      );
    })
    .catch((error: { clientMessage: string }) => {
      error.clientMessage = "Can't find quiz";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const saveQuizQuestion = (
  id: any,
  payload: { questionId: any; statement: any; parts: any[] }
) => (dispatch: (arg0: { payload: any; type: string }) => void) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer.updateQuestion(id, payload);
};
